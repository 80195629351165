.slick-next {
  position: absolute !important;
  background-image: url("../public/right.png") !important;
  background-repeat: no-repeat !important;
  width: 25px !important;
  height: 50px !important;
  object-fit: cover !important;
  background-size: 100% !important;
  right: -40px;
}
.slick-slider {
  margin-bottom: 1rem;
}
.slick-prev {
  position: absolute !important;
  background-image: url("../public/left.png") !important;
  background-repeat: no-repeat !important;
  width: 25px !important;
  height: 50px !important;
  object-fit: cover !important;
  background-size: 100% !important;
  left: -40px;
}

.slick-next:before,
.slick-prev:before {
  opacity: 0;
  display: none !important;
}

.swipper-img {
  height: 266px;
  width: 289px;
}

@media (min-width: 0px) and (max-width: 800px) {
  .slick-prev {
    left: 10px;
  }

  .slick-next {
    right: 10px;
  }
  .swipper-img {
    height: 266px;
    width: 214px;
    background-repeat: no-repeat;
  }
}
