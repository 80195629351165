.user-sidebar-nav {
  /* border-right: solid 0.0625rem rgba(244, 186, 86, 0.25); */
}

.connectBox {
  padding: 6.25rem 3.75rem;
}
.user-sidebar-nav ul {
  padding: 0;
  margin: 0;
  margin: 0rem 0;
  list-style: none;
  margin-right: 1.25rem;
  margin-top: 1.25rem;
}

.user-sidebar-nav ul li {
  margin-top: 0rem;
  display: block;
}

.user-sidebar-nav ul li + li {
  margin-top: 0;
  border-top: solid 0.0625rem rgba(244, 186, 86, 0.25);
}

.user-sidebar-nav ul li a {
  font-size: 1rem;
  padding: 1rem 0;
  display: flex;
  line-height: 1.125rem;
  text-decoration: none;
  background: transparent;
  color: #e2bf65 !important;
  opacity: 0.75;
  letter-spacing: 0.0625rem;
}

.user-sidebar-nav ul li a:hover,
.user-sidebar-nav ul li a.active {
  color: #e2bf65 !important;
  opacity: 1;
}

.user-sidebar-nav ul li a svg {
  width: 1.25rem;
  height: 1.25rem;
  fill: #e2bf65;
  margin-right: 0.9375rem;
  margin-top: -0.0625rem;
}

.user-sidebar-nav ul li a:hover svg,
.user-sidebar-nav ul li a:active svg {
  fill: #fff;
}

.user-info {
  font-size: 1.125rem;
  color: #fff;
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  text-align: left;
}

.user-info .address {
  font-size: 0.8125rem;
}

.user-info .value-no {
  font-size: 0.8125rem;
  color: #e2bf65;
}

.user-info .userimg {
  width: 5rem;
  height: 5rem;
  border-radius: 0.5rem;
  border: solid 0.25rem #fff;
}

.user-right-side {
  padding-bottom: 1.25rem;
  color: #000;
}

.user-right-side h1 {
  color: #e2bf65;
  font-size: 1.5625rem;
  margin-bottom: 1.25rem;
  margin-top: 1.25rem;
}

.card-info {
  padding: 0.625rem;
  font-size: 0.8125rem;
  margin-bottom: 0rem;
  border-radius: 0.9375rem;
  text-align: center;
  background-color: #fff;
  border: none;
  display: block;
}

.card-info .c-in-img {
  border-radius: 0.5rem;
  margin-bottom: 0.625rem;
  border-radius: 0.9375rem;
  background-color: #333333;
  display: block;
  width: 100%;
  height: auto;
}

.card-info h2 {
  font-size: 1.125rem;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  display: block;
}

.card-info .btn-primary {
  color: #000;
}
.card-info .btn-primary {
  font-size: 0.875rem;
  background-color: #e2bf65 !important;
  border: solid 0.0625rem #e2bf65;
  color: #fff !important;
  font-weight: bold;
  border-radius: 1.875rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0.5rem;
  display: block;
  padding: 0.3125rem 1.5625rem;
}

.card-info .btn-primary:hover,
.card-info .btn-primary:focus { 
  background: #634d5a !important;
  border: solid 0.0625rem #634d5a !important;
  color: #fff !important; 
}
.card-info p {
  margin-bottom: 0;
}

.c-link {
  font-size: 0.8125rem;
  font-weight: bold;
  opacity: 0.75;
  color: #000;
  margin-left: 0.5rem;
}

.c-link:hover {
  opacity: 1;
}

.card-img {
  width: 135px;
  height: auto;
  border-radius: 0.9375rem;
  background-color: #333333;
}

.right-content-list {
  background-color: #e2bf65;
  color: #000;
  padding: 0.3125rem 0.9375rem;
  font-size: 0.8125rem;
  border-radius: 0.9375rem;
  font-weight: bold;
  margin-bottom: 0.9375rem;
}

.right-content-list .btn {
  padding: 0.125rem 0.5rem;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.right-content-list .img-list {
  width: 5.5rem;
  height: 5.5rem;
  border-radius: 0.5rem;
  border: solid 0.25rem #fff;
}

.pb-15 {
  padding-bottom: 0.75rem !important;
  padding-top: 0.75rem !important;
}

.news-card {
  background-color: #634d5a;
  padding: 0.9375rem 1.25rem;
  border-radius: 0.9375rem;
  font-weight: bold;
  color: #fff;
  text-align: left;
}
.news-card ul {
  margin: 0;
    padding: 0;
    list-style: none;
    padding-left: 1.5625rem;
}
.news-card ul li {
  position: relative;
  margin-bottom: 0.3125rem;
}
.news-card ul li:before {
  left: -1.5625rem;
  top: 0.625rem;
  height: 0.0625rem;
  width: 0.875rem;
  background:#e2bf65;
  position: absolute;
  content: "";
}
.news-card h2 {
  font-size: 1.5rem;
  margin-bottom: 1.125rem;
}

.news-card a {
  color: #fff;
  text-decoration: underline;
  margin-bottom: 0.5rem;
  font-weight: normal;
  font-size: 0.875rem;
  display: block;
  opacity: 0.75;
}

.news-card a:hover {
  opacity: 1;
}

@media (max-width: 1366px) {
  .right-content-list {
    padding: 0.3125rem 0.625rem;
  }

  .right-content-list .img-list {
    width: 4.6875rem;
    height: 4.6875rem;
    border-radius: 0.5rem;
    border: solid 0.25rem #fff;
  }

  .card-info h2 {
    font-size: 1rem;
  }

  .news-card {
    padding: 0.9375rem;
  }

  .card-img {
    width: 6.875rem;
  }

  .news-card h2 {
    font-size: 1.25rem;
    margin-bottom: 0.75rem;
  }

  .user-right-side h1 {
    color: #e2bf65;
    font-size: 1.375rem;
  }

  .user-sidebar-nav ul {
    margin-right: 0;
  }
}

@media (max-width: 991px) {
  .right-content-list {
    margin-top: 0.9375rem;
  }
}

@media (max-width: 767px) {
  .user-info .userimg {
    width: 3.75rem;
    height: 3.75rem;
    border: solid 0.125rem #fff;
  }

  .user-sidebar-nav ul {
    margin-top: 0;
  }

  .card-img {
    width: 6.875rem;
  }
}

@media (max-width: 767px) {
  .connectBox {
    padding: 0rem 0rem;
  }
}
