.staff-images {
  height: auto;
  margin: 0px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.staff-detail {
  width: 250px;
  margin: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-postion {
  color: var(--text-white-lightly);
  font-weight: 100;
  font-size: 22px !important;
}
.staff-avatar {
  /* width: 190px;
  height: 190px; */
  background: var(--gradient-color);
  border-radius: 50%;
  padding: 2px;
}
.staff-avatar img {
  width: 100%;
  height: 100%;
}

.staff-detail h4 {
  font-family: "Agency FB", sans-serif;
  color: var(--color-white);
  margin-bottom: 5px;
  /* font-weight: 800; */
  font-size: 26px;
  margin-top: 15px;
}

.staff-detail span {
  color: var(--text-white-lightly);
  margin: 3px 0px;
  font-size: 14px;
}

.staff-social-media-icon {
  display: flex;
  width: 100%;
  justify-content: center;
}

.staff-social-media-icon div:hover {
  cursor: pointer;
}

.staff-social-media-icon div {
  width: 31px;
  height: 30px;
  margin: 10px 3px;
}

.link-connect {
  margin-right: 0px !important;
}
.staff-social-media-icon div img {
  width: 100%;
  height: 100%;
}

.footer {
  /* height: 100px; */
  padding: 46px 6.5rem;
  width: 100%;
  background-color: var(--bg-secondary);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 100px; */
}

.footer-icons {
  display: flex;
}

.footer-icons div {
  width: 40px;
  height: 40px;
  margin-right: 7px;
}

.footer-icons div img {
  width: 100%;
  height: 100%;
}

.footer-icons div:hover {
  cursor: pointer;
}

.footer-details {
  text-align: start;
}

.footer-list ul {
  display: flex;
  margin: 0px;
  padding-left: 0px;
  flex-direction: row;
}

.footer-list ul li {
  list-style: none;
  margin-top: 0px !important;
  color: #fcfcfc;
  margin-right: 10px;
  font-weight: 800;
}

.footer-list ul li:hover {
  cursor: pointer;
}

.border-list {
  /* border-right: 3px solid #fcfcfc; */
  /* border-left: 3px solid #fcfcfc; */
  margin-right: 8px;
  padding-right: 10px;
  padding-left: 10px;
}
.border-list-right-0 {
  border-right: none;
}

.copy-right h5 {
  color: #aeaeae;
  font-size: 13px;
  margin: 0px;
  margin-top: 10px;
}

@media (min-width: 0px) and (max-width: 800px) {
  .footer {
    flex-direction: column;
    justify-content: center;
  }

  .footer-details {
    margin-left: 0px;
    text-align: center;
  }

  .footer-icons {
    margin-right: 0px;
    margin-top: 10px;
    margin-bottom: 15px !important;
  }
}

@media (min-width: 0px) and (max-width: 980px) {
  .owner-ship {
    display: flex !important;
    flex-direction: column !important;
  }

  .owner-ship img {
    margin-top: 15px;
    width: 100% !important;
  }
}

@media (min-width: 0px) and (max-width: 480px) {
  .footer-list ul {
    flex-direction: column;
  }

  .footer-list ul li {
    margin-top: 10px;
  }

  .border-list {
    border: none !important;
  }

  .footer {
    flex-direction: column;
    height: auto;
    padding: 30px 0px;
  }
}
