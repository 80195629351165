@import url("https://fonts.cdnfonts.com/css/agency-fb");
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css");

:root {
  /* --gradient-color: linear-gradient(
    90deg,
    rgba(51, 74, 230, 1) 0%,
    rgba(0, 225, 205, 1) 100%
  ); */
  --gradient-color: linear-gradient(90deg,
      rgba(90, 69, 90, 1) 0%,
      rgba(245, 192, 87, 1) 100%);
  --container-bg-color: #f0f0f0;
  --text-white-lightly: #f4f4f5;
  --text-white-lightly: #f4f4f5;
  --color-white: #ffffff;
  --bg-color: #121323;
  --text-gold: #f4bf57;
  --bg-secondary: #262738;
  --text-up-color: #f4bf57;

  --font-family-agency-fb: "Agency FB", sans-serif;
}

/* .navbar-toggler-icon {
  background-image: url(
    data:image/svg + xml,
    %3Csvgxmlns="http://www.w3.org/2000/svg"width="24"height="24"fill="red"class="bi bi-list"viewBox="0 0 16 16"%3E%3Cpathd="M2 3.5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11a.5.5 0 0 0-.5.5zm0 5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11a.5.5 0 0 0-.5.5zm0 5a.5.5 0 0 0 .5.5h11a.5.5 0 0 0 0-1h-11a.5.5 0 0 0-.5.5z"/%3E%3C/svg%3E
  );
} */
.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(245, 192, 87, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.navbar-toggler {
  border-color: rgba(245, 192, 87, 1);
  border-radius: 0px;
}

.t-link a {
  text-decoration: none;
}

.t-link a:hover {
  color: var(--text-white) !important;
}

body {
  background-color: var(--bg-color);
  color: var(--color-white);
}

/* ul {
  list-style: none;
} */
a {
  text-decoration: none !important;
}

button {
  pointer: cursor;
}

button:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.navbar {
  /* color: var(--text-white-lightly) !important; */
  background-color: var(--bg-secondary);
}

.form-control {
  border-radius: 0px;
  border: 2px solid rgba(245, 192, 87, 1);
  /* position: relative;

  background: var(--gradient-color);
  padding: 3px;
  display: inline-block; */
  /* border-radius: 9999em; */
}

.power-logo img {
  height: 6vmin;
}

.text-gold {
  color: var(--text-gold) !important;
}

.custom-list {
  list-style: none;
  /* Remove default list-style */
  padding: 0;
}

.custom-list li {
  display: flex;
  /* Make list items flex containers */
  align-items: center;
  /* Vertically center items */

  /* Add spacing between list items */
  margin-bottom: 40px;
}

.custom-list li .list-item-container {
  display: flex;
  flex-direction: column;
}

.chart-fractional {
  /* margin: 0;  */
}

/* .ul-style-list li .list-item-container {
  margin-bottom: 10px !important;
} */
.heading-light {
  font-size: 40px;
  color: var(--text-white-lightly);
  font-weight: 100;
}

.heading-white {
  font-size: 70px;
  color: var(--color-white);
  /* font-weight: 100; */
  margin-bottom: 50px;
}

.horizontal-line {
  border: none;
  height: 3px;
  /* Adjust the height as needed */
  background: var(--gradient-color);
  /* Gradient colors */
  width: 70%;
}

.header-section {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.text-justify {
  text-align: justify !important;
}

* {
  box-sizing: border-box;
}

.text-blue {
  font-family: sans-serif !important;
  color: #1cbdda;
  font-size: 20px;
  font-weight: 800;
}

.news-text-h {
  color: #3449e4;
}

.App {
  text-align: center;
}

.container-connect {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

.border-img {
  border: 3px solid transparent;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #2193b0, #6dd5ed) border-box;
  border-image-slice: 1;
  border-radius: 20px;
}

.content {
  min-height: 20vh;
  background: var(--container-bg-color);
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.date {
  align-self: flex-end;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.custom-border {
  border: 3px solid transparent;
  padding: 30px;
  background: linear-gradient(#fff, #fff) padding-box,
    linear-gradient(45deg, #2193b0, #6dd5ed) border-box;
  border-image-slice: 1;
  border-radius: 20px;
}

.mt-70 {
  margin-top: -83px !important;
}

.custom-padding {
  padding: 0 30px;
}

.circle-image {
  border-radius: 50%;
}

.text-4 {
  font-size: 4.5rem;
  font-weight: normal;
}

.text-content {
  padding-left: 30px;
}

.buttons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.box {
  /* width: 150px; */
  /* height: 150px; */
  background-color: var(--container-bg-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 20px;
  margin-top: 15px;
  /* Adjust margin as needed */
}

.box .text-center {
  padding: 25px;
}

.panel-title>a:before {
  float: right !important;
  font-family: FontAwesome;
  content: "\f068";
  padding-right: 5px;
}

.float-left {
  float: left;
}

.panel-title>a.collapsed:before {
  float: right !important;
  content: "\f067";
}

.panel-title>a:hover,
.panel-title>a:active,
.panel-title>a:focus {
  text-decoration: none;
}

button:focus {
  outline: none;
}

.agency-fb-font-family-regular {
  font-family: "Agency FB", sans-serif;
}

.agency-fb-font-family {
  font-family: "Agency FB", sans-serif;
  font-weight: bold !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.powerBy-text {
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--text-white-lightly);
  margin-top: 50px;
}

.m-0 {
  margin-left: 0px !important;
}

/* .nav-item a:hover {
  color: #b7b7b7;
} */
.list-item-container {
  display: flex;
  /* Use flex display to align items in a row */
  align-items: start;
  /* Vertically center items */
}

.list-item-container img {
  max-width: 80px;
  /* Adjust the image size as needed */
  margin-right: 20px;
  /* Add some margin between the image and h1 */
}

.list-item-container h1 {
  display: inline;
  /* Display the h1 element inline with the image */
}

/* .btn-nav {
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  font-size: 13px;
  padding: 11px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
  border: 1px solid transparent;
  cursor: pointer;
} */

.icons {
  list-style: none;
  text-decoration: none;
  color: white;
}

a:hover {
  list-style: none !important;
  text-decoration: none !important;
  color: white !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

p {
  font-family: "corbel";
}

.blockChain-text {
  font-size: 40px;
  font-weight: 100;
  color: var(--text-white-lightly);
  padding: 14px 10px 10px 10px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.section-top-bottom {
  padding-top: 5%;
  padding-bottom: 5%;
}

.section-legal {
  padding: 6.5rem 6.5rem;
}

.section-slider {
  /* padding-top: 2.5%;
  padding-bottom: 2.5%; */
  padding: 6.5rem 6.5rem;
}

.header-b-3 {
  padding-bottom: 1.8%;
}

.section-two {
  /* margin-top: 5%; */
  margin-bottom: 5%;
}

.margin-top-bottom-5-percent {
  margin-bottom: 5%;
}

/* .fractional-section {
  margin-bottom: 0;
  margin-top: 37px;
} */

.access-btn {
  gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.marg-b {
  margin-bottom: 40px;
}

.logo {
  gap: 50px;
  /* margin-bottom: 70px; */
}

.owenership-section {
  /* background-color: #000000d9; */
  padding: 6.5rem 6.5rem;
}

.card {
  height: 222px;
  width: 220px !important;
}

.btn-swipper {
  position: absolute;
  bottom: 0;
  margin-bottom: 5px;
  border-radius: 11px;
  height: 33px;
  width: 160px;
  cursor: pointer;
  color: white !important;
  padding: 0px;
  padding-top: 4px;
}

.swiper-container {
  position: relative;
}

.gemstone-text {
  font-size: 100px;
  /* font-weight: bold; */
}

.btn-access {
  /* width: 60%; */
  font-size: 13px;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 12px;
  border: none;
  cursor: pointer;
  background: var(--gradient-color);
}

.btn-gradient {
  margin: 30px 10px;
  width: 250px;
  font-size: 15px;
  padding: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 0px;
  cursor: pointer;
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  /* background: var(--gradient-color); */
  /* border: 0px; */
  border: 1px solid transparent;
  /* color: white; */
}

.btn-connect {
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  margin-right: 5px;
  border-radius: 0px;
  border: 1px solid transparent;
  margin-left: 50px;
  font-size: 22px !important;
}

.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  border: 1px solid transparent;
  background: linear-gradient(#121323, #121323) padding-box,
    var(--gradient-color) border-box;
}

.btn-outline-primary {
  margin: 30px 10px;
  background: linear-gradient(#121323, #121323) padding-box,
    var(--gradient-color) border-box;
  font-size: 15px;
  color: white;
  padding: 20px;
  border-radius: 0px;
  border: 1px solid transparent;
  cursor: pointer;
  /* background: var(--gradient-color); */
}

.btn-outline-primary:hover {
  /* background: var(--gradient-color); */
  /* border: unset; */
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  border: 1px solid transparent;
}

.nav-item a {
  color: var(--text-white-lightly) !important;
  text-transform: uppercase;
  font-family: var(--font-family-agency-fb);
  font-size: 22px;
}

.navbar {
  transition: all 0.5s;
}

.navbar {
  background: none;
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  padding: 20px 10px;
  z-index: 2;
  /* Ensure it's above other content */
}

.navbar-scrolled {
  background-color: var(--bg-secondary);
  opacity: 0.96;
}

.btn-buy {
  font-size: 13px;
  color: black;
  padding: 20px;
  background-color: white !important;
  border-radius: 12px;
  border: linear-gradient(to right, #6dd5ed, #2193b0);
}

.btn-buy:hover {
  font-size: 13px;
  cursor: pointer;
  color: black;
  padding-right: 20px;
  background-color: white !important;
  border-radius: 12px;
  border: linear-gradient(to right, #6dd5ed, #2193b0);
}

.btn-buy :hover {
  color: black;
  background-color: white !important;
}

.unique-text {
  color: white;
  font-size: 40px;
  font-weight: 100;
}

.vision-text {
  color: white;
  font-size: 70px;
}

.text-lightly {
  color: var(--text-white-lightly);
}

.owner-ship {
  /* margin-top: 30px; */
  display: flex;
}

.mb-50 {
  margin-bottom: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.legal-notice-text {
  text-align: left;
  text-align: justify;
  margin: 0 0px;
  font-size: 22px;
}

.available {
  font-size: 20px;
  font-weight: 100;
}

.fractional {
  font-size: 50px;
}

.chart-fractional {
  font-size: 22px;
  color: white;
  float: left;
  margin-bottom: 0;
}

.chart-fractional-offer {
  font-size: 16px;
  color: black;
  float: left;
  margin-bottom: 0;
}

.ractional-rocks-color {
  color: #333333;
}

/* Hover styles for the link */
.ractional-rocks-color:hover {
  color: #333333 !important;
  /* font-size: 20px; */
  /* color: rgba(51, 74, 230, 1) !important; */
  /* background-image: var(--gradient-color) !important; */
}

.card {
  background: linear-gradient(#fff, #fff) padding-box,
    var(--gradient-color) border-box;
  border: 3px solid transparent;
  /* border-radius: 23px; */
  position: relative;
  height: 272px !important;
}

.centered-image {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.page-note-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.btn-swipper {
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  border: 1px solid transparent;
  border-radius: 0px;
  font-size: 14px;
  position: absolute !important;
  left: 63px !important;
  bottom: 5px !important;
  /* width: 93% !important; */
  /* height: 58px; */
}

.gradient-border {
  width: 150px;
  height: 150px;
  border: 10px solid transparent;
  border-image: linear-gradient(45deg, #ff6c6c, #4585f9);
  border-image-slice: 1;
  border-image-width: 10px;
}

.fa {
  padding: 20px;
  font-size: 30px;
  width: 50px;
  text-align: center;
  text-decoration: none;
  margin: 5px 2px;
}

.social-images {
  height: 100px;
  width: 100px;
  margin-bottom: 1rem;
  float: left;
}

.available-asset {
  font-size: 18px;
  font-weight: 100;
  float: left;
  display: block !important;
  margin: 0px !important;
}

.available-asset-offer {
  /* color: white; */
  font-size: 14px;
  /* font-weight: 100; */
  float: left;
  padding-left: 40px;
}

ul {
  display: flex;
  flex-direction: column;
}

/* .nav-links {
  padding-top: 11px;
  margin-right: 50px;
} */
/* li {
  margin-top: 30px;
  list-style: none;
} */

.owenership-data {
  text-align: left;
  font-size: 22px;
  text-align: justify;
  color: white;
}

.text-p {
  font-size: 22px !important;
}

.vision-image {
  margin-top: 40px;
  width: 70%;
  height: 250px;
}

.plr-320 {
  padding: 0 320px;
}

.logo-img {
  width: 450px;
}

.apexcharts-legend-text {
  display: none !important;
}

.donut-chart {
  display: flex;
  justify-content: end;
  z-index: 1;
}

.chart-list {
  position: relative;
  left: -38px;
  z-index: 0;
}

.chart-header {
  display: flex;
}

/* .navbar {
  position: relative;
  background-color: #000000d9;
} */

/* .navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: var(--gradient-color);
} */

.big-screen-none {
  display: none;
}

.text-left {
  text-align: left;
}

.header-logo {
  width: 150px;
}

.donut-chart-width {
  width: 570px;
  /* height: 570px; */
}

.donut-chart-width-f {
  width: 100%;
  /* height: 570px; */
}

.toggle-button {
  border: none;
  padding: 0px;
  margin-left: 77px;
}

.chart-text {
  position: absolute;
  top: 195px;
  left: 103px;
  font-size: 19px;
  color: white;
}

.chart-text-fractional {
  position: absolute;
  top: 114px;
  left: 20px;
  font-size: 16px;
  color: black;
}

.hide-on-lg {
  display: none;
}

.clr-white {
  color: white;
}

.togller-l {
  /* margin-left: 153px; */
  border: none;
  margin-right: 0px !important;
}

.accordion {
  border: 0px !important;
}

.sec {
  text-align: left;
  padding: 50px 50px;
}

.section-terms {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.section-offers {
  text-align: left;
  padding: 50px 50px;
  /* display: flex;
  flex-direction: column; */
}

.section-offers p {
  padding-right: 50px;
}

.section-terms h2 {
  font-size: 35px;
  /* padding-top: 30px; */
}

.h2-m {
  margin: 20px 0px !important;
}

/* .ection-offers h6 {
  padding-top: 30px;
} */

.text-bold {
  font-weight: bold;
}

.btn-keep {
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  border: 1px solid transparent;
  border-radius: 0px;
  font-size: 13px;
  width: 50% !important;
  margin: 10px 0;
  cursor: pointer;
}

.btn-submit {
  width: 100% !important;
}

.btn-w-40 {
  width: 49% !important;
}

.mr-10 {
  margin-right: 10px;
}

.text-justyfy {
  text-align: justify;
}

#chart {
  width: 100%;
  height: 100%;
  margin-left: -66px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-23 {
  margin-top: 23px;
}

.btn-back-home {
  width: 10% !important;
  cursor: pointer;
  color: white !important;
}

/* .pt-10px {
  padding-top: 10px !important;
} */
.chart-list-f {
  padding: 0 !important;
  left: -100px;
  /* left: 0 !important; */
  /* padding-top: 40px !important; */
  /* padding-left: 40px !important; */
}

.h6-gray {
  color: #3348e1;
}

.accordion-button {
  width: 100%;
  text-align: left;
  padding: 10px 0px;
  /* background: white; */
  border: none;
  color: var(--text-up-color);
  background: linear-gradient(#121323, #121323) padding-box,
    var(--gradient-color) border-box;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  font-size: 22px;
}

.accordion-body {
  padding: 20px;
  font-size: 18px;
  text-align: justify;
  background: var(--bg-color);
}

.accordion-button:not(.collapsed) {
  /* color: white; */

  color: var(--text-up-color);
  background-color: none;
  box-shadow: none;
}

.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: none;
}

.accordion-button .icon {
  float: right;
  margin-right: 20px;
  font-weight: 500;
}

/* .accordion-button::before {
  content: "+";
  float: right;
  margin-right: 20px;
  font-weight: 600;
}
.accordion-button[aria-expanded="true"]::before {
  content: "-";
} */
.accordion-button::after {
  content: "+";
  font-weight: 100;
  color: var(--text-up-color);
  transform: translateY(-4px);
  top: 20px;
}

.accordion-button:not(.collapsed)::after {
  content: "-";
  transform: translate(-5px, -4px);
  transform: rotate(0deg);

  color: var(--text-up-color);
}

.accordion-button:not(.collapsed)::after,
.accordion-button::after {
  width: auto;
  height: auto;
  background-image: none;
  position: absolute;
  right: 15px;
  top: -1px !important;
  font-size: 24px;
}

.accordion-item {
  border: none !important;
  color: var(--text-white-lightly);
}

.accordion .item .accordion-header button {
  background-color: transparent;
  box-shadow: none;
}

.accordion .item {
  border: 0;
}


.login .modal-content {
  background: linear-gradient(#121323, #121323) padding-box,
    var(--gradient-color) border-box;
  color: white;
  border-radius: 0px;
  border: 1px solid transparent;

}

.login .modal-body {
  padding: 1.875rem;
  text-align: left;
}

.login .modal-body h2 {
  font-size: 20px; font-weight: 600;
}

.login .modal-body p {
  font-size: 1rem;
}

.login .modal-body .btn-primary {
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 7px;
  border-radius: 0px;
  border: 1px solid transparent;
  font-size: 22px !important;
  width: 100%;
  margin: 15px 0 0 0;

}

.login .modal-body .btn-primary:active,
.login .modal-body .btn-primary:focus,
.login .modal-body .btn-primary:hover {
  border: 1px solid transparent;
  background: linear-gradient(#121323, #121323) padding-box,
    var(--gradient-color) border-box;
}

.login .modal-body .form-control {
  padding: 0.625rem 12px;
}

.login .btn-close {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  filter: invert();
  opacity: 8;
}

.select-logo {
  margin-top: 10px;
  margin-bottom: 0;
  display: block;
  padding: 15px 12px;
  text-align: center;
  background: linear-gradient(#121323, #121323) padding-box,
    var(--gradient-color) border-box;
  border-radius: 0px;
  border: 1px solid transparent;
  line-height: 1;
}

.select-logo:hover {
  background: var(--gradient-color) padding-box,
    var(--gradient-color) border-box;
}

.select-logo img {
  width: auto;
  height: 26px;
}

@media (max-width:480px) {
  .login .modal-body {
    padding: 15px;
  }

  .select-logo {
    padding: 10px 5px;
    text-align: center;
    margin-top: 0;
  }

  .select-logo img {
    height: 20px;
  }

  .login .modal-body h2 {
    font-size: 16px;
  }

  .login .modal-body p {
    font-size: 14px;
  }
}

@media (max-width:375px) {
  .select-logo {
    text-align: center;
  }

  .select-logo img {
    height: 20px;
  }
}



@media (min-width: 0px) and (max-width: 800px) {

  /* .btn-back-home {
    width: 50% !important;
  } */
  .navbar {
    padding: 10px 10px;
  }

  .text-p {
    font-size: 16px !important;
  }

  .header-logo {
    width: 170px;
  }

  .navbar {
    background: var(--bg-secondary);
  }

  .btn-connect {
    margin-left: 0px;
    margin-right: 20px;
    width: 100%;
  }

  .btn-outline-primary {
    margin-top: 0px;
  }

  .btn-m {
    flex-direction: column;
    /* Change the flex-direction for stacking buttons */
    align-items: center;
    /* Center-align the buttons */
  }

  .blockChain-text {
    font-size: 18px;
  }

  .text-content {
    padding-left: 0px;
  }

  .p-l-r {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }

  .btn-100 {
    width: 100% !important;
  }

  .date {
    top: 10px;

    align-items: center;
    /* bottom: auto; */
    /* position: unset;
    text-align: center; */
  }

  .text-4 {
    font-size: 3.5rem;
  }

  .text-15 {
    font-size: 15px !important;
  }

  .t-centered {
    text-align: center;
  }

  .accordion-button {
    font-size: 14px;
  }

  .section-legal {
    padding: 6.5rem 6.5rem;
  }

  .f-20 {
    font-size: 20px !important;
  }

  .reverse-columns {
    flex-direction: column-reverse;
  }

  .chart-list-f {
    padding: 0 !important;
    left: -39px;
    /* left: 0 !important; */
    /* padding-top: 40px !important; */
    /* padding-left: 40px !important; */
  }

  .chart-text-fractional {
    top: 127px;
    left: 105px;
    font-size: 13px;
  }

  .mt-30 {
    margin-top: 30px;
  }

  .btn-keep {
    width: 99% !important;
  }

  .sec {
    text-align: left;
    padding: 50px 0px;
  }

  .section-terms h2 {
    font-size: 22px;
  }

  .section-terms h5 {
    font-size: 16px;
  }

  .section-offers {
    text-align: left;
    padding: 50px 10px;
  }

  .section-offers h2 {
    font-size: 20px;
  }

  .section-offers h5 {
    font-size: 18px;
  }

  .section-offers p {
    padding-right: 0px;
  }

  #chart {
    margin-left: -5px;
  }

  .chart-fractional {
    font-size: 18px;
  }

  .hide-on-mobile {
    display: none;
  }

  /* .navbar-brand-m {
    margin-left: -60px !important;
  } */
  .width-100 {
    width: 100%;
    font-size: 14px;
    /* font-weight: bold; */
  }

  /* .navbar-nav {
    text-align: center;
    font-size: 20px;
  } */
  /* .navbar-nav .nav-item + .nav-item {
  } */
  /* .navbar-toggler {
    background: var(--gradient-color);
    color: #fff !important;
    padding: 0.1875rem 0.375rem;
    border-radius: 3px;
    marginborder-right: 0.625rem;
    margin-top: 0.3125rem;
    margin-bottom: 0.3125rem;
  } */
  /* Remove the color change on the navbar toggler button's :focus and :active states */
  /* .navbar-toggler:focus,
  .navbar-toggler:active {
    outline: none; 
    box-shadow: none;
  }
  .navbar-nav .nav-item .nav-link {
    color: white;
  } */

  .mb-10 {
    margin-bottom: 9px !important;
  }

  /* .nav-item a {
    padding: 5px 0px !important;
  }
  .navbar-nav li {
    margin-top: 0px !important;
  } */

  .m-t-20 {
    margin-top: 20px !important;
  }

  /* .navbar-nav {
    margin-bottom: 0px !important;
  } */
  /* .powerBy-text {
    margin-top: 10px;
  } */

  .hide-on-mobile {
    display: none;
  }

  .hide-on-lg {
    display: block;
  }

  .access-btn {
    flex-direction: column;
    /* Change the flex-direction for stacking buttons */
    align-items: center;
    /* Center-align the buttons */
  }

  .btn-access,
  .btn-buy {
    width: 60%;
    /* Make buttons take up the full width */
    margin-bottom: 20px;
    /* Add some spacing between the buttons */
  }

  .owner-ship {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
  }

  .m-pr-0 {
    padding-right: 20px !important;
  }

  .m-pl-0 {
    padding-left: 15px !important;
  }

  .social-images {
    height: 70px;
    width: 75px;
  }

  .legal-notice-text {
    text-align: center;
    margin: 0 250px;
  }

  .legal-notice-text {
    /* margin: 0 15px !important; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-align: justify;
    font-size: 16px;
    max-width: 800px;
    /* Adjust the max-width to your preference */
    margin: 0 auto;
    /* This centers the container horizontally */
  }

  .section-legal {
    padding: 6.5rem 6.5rem;
  }

  .chart-text {
    position: absolute;
    top: 132px;
    left: 105px;
    font-size: 14px;
    color: white;
  }

  .owenership-section {
    padding: 0;
  }

  .donut-chart-width {
    width: 100%;
    height: 100% !important;
  }

  .vision-image {
    width: 300px !important;
    height: 300px !important;
    margin-bottom: 20px;
  }

  .logo-img {
    width: 300px !important;
    margin-top: 30px;
  }

  .gemstone-text {
    font-size: 50px !important;
  }

  .chart-header {
    display: flex !important;
    flex-direction: column !important;
  }

  .chart-list {
    padding: 0 !important;
    /* left: 0 !important; */
    padding-top: 40px !important;
    padding-left: 40px !important;
  }

  ul {
    margin: 0 !important;
    /* margin-bottom: 20px !important; */
  }

  .available-asset {
    text-align: left !important;
  }

  .swiper-slide {
    width: max-content;
    display: flex !important;
    justify-content: center !important;
    width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .section-one {
    margin-top: 10%;
    /* margin-bottom: 10%; */
    padding: 17px !important;
    box-sizing: border-box !important;
  }

  .logo {
    gap: 50px;
    margin-bottom: 15px !important;
  }

  .owenership-section {
    padding: 5rem 1.1rem;
  }

  .section-slider {
    padding: 5rem 1.1rem;
  }

  .section-legal {
    padding: 5rem 1.1rem;
  }

  .owenership-data {
    /* text-align: center; */
    color: white;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    max-width: 800px;
    /* Adjust the max-width to your preference */
    margin: 0 auto;
    /* This centers the container horizontally */
  }

  .heading-light {
    font-size: 25px;
  }

  .heading-white {
    font-size: 50px;
  }

  .new-p {
    padding-top: 15px;
  }

  .owenership-section h3 {
    font-size: 17px !important;
    font-weight: 800 !important;
  }

  /* .owenership-section h3 {
    text-align: left !important;
  } */

  .fractional {
    margin-bottom: 10px;
  }

  /* .owenership-section p {
    font-size: 12px !important;
  } */

  /* .header-logo {
    width: 170px !important;
    margin-bottom: 3px;
  } */
  .btn-swipper {
    left: 25px !important;
  }

  .plr-320 {
    padding: 0 30px;
  }

  .access-btn {
    gap: 0px;
    /* margin-top: 30px; */
    margin-bottom: 0px;
  }

  .marg-b {
    margin-bottom: -20px;
  }

  .big-screen-none {
    display: block;
  }

  .mobile-screen-display-none {
    display: none;
  }
}

@media (min-width: 0px) and (max-width: 1190px) {
  .chart-header {
    display: flex !important;
    flex-direction: column !important;
  }
}

@media (min-width: 0px) and (max-width: 980px) {
  .apexcharts-legend {
    display: none !important;
  }

  .chart-header {
    justify-content: center !important;
    padding: 0 !important;
  }
}

@media (min-width: 1080px) and (max-width: 1980px) {
  .card {
    width: 295px !important;
  }

  .hide-on-web {
    display: none;
  }

  .fractional {
    margin-bottom: 20px;
  }
}